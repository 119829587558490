/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

body {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  //cursor:none;
}
ion-buttons.flags {
  float: right;
  margin-left: 8px;
  //cursor: none;
}
button {
  //cursor: none;
}
.payment-select-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.payment-result-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.countdown-modal {
  --width: 360px;
  --height: 155px;
}

.payment-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.contactless-modal {
  --width: 100%;
  --height: 100vh;
  --border-radius: 0;
}

.loyalty-payment-keypad-modal {
  --width: 100%;
  --height: 100vh;
  --border-radius: 0;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.loyalty-payment-accept-modal {
  --width: 100%;
  --height: 100vh;
  --border-radius: 0;
}
.products-select-modal {
  position: fixed;
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  top: 0px;
  bottom: 0px;
}
.nfc-start-modal, .nfc-accepted-modal, .nfc-error-modal {
  --width: 100%;
  --height: 100vh;
  --border-radius: 0;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  pointer-events: none;
}

ion-backdrop {
  opacity: 0.9 !important;
}

:root {
  --ion-color-primary: #007767;
  --ion-color-primary-rgb: 0,119,103;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #00695b;
  --ion-color-primary-tint: #1a8576;

  --ion-color-secondary: #5ABBA2;
  --ion-color-secondary-rgb: 90,187,162;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #4fa58f;
  --ion-color-secondary-tint: #6bc2ab;

  --ion-color-tertiary: #85CEE4;
  --ion-color-tertiary-rgb: 133,206,228;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #75b5c9;
  --ion-color-tertiary-tint: #91d3e7;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #FBBC43;
  --ion-color-warning-rgb: 251,188,67;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #dda53b;
  --ion-color-warning-tint: #fbc356;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #b488bc;
  --ion-color-light-rgb: 180,136,188;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #9e78a5;
  --ion-color-light-tint: #bc94c3;
}
.subnavigation {
  height: 90px;
  width: 100%;
  position: relative;
  background-color: #fff;
}
.nav {
  height: 90px;
  overflow: hidden;
  padding: 8px;
}
.logoswitchpay {
  width: 25%;
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}
.logoswitchpay img {
  height: 50px;
}
.top_buttons {
  float: right;
}
.top_button {
  float: right;
  height: 75px;
  width: 120px;
  background-color: #A883B3;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  margin-left: 8px;
}
.button_title {
  font-size: 17px;
  font-family: "Roboto Slab", serif;
}
.bottom_nav {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  width: 121px;
  font-size: 15px;
  font-family: "Roboto Slab", serif;
  height: 35px;
  background-color: #167869;
  padding: 8px;
  text-align: center;
  color: #52ad97;
  font-weight: 800;
}

.logout {
  float: left;
  font-weight: 800;
}
.main-container {
  font-family: "Roboto Slab", serif;
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  font-weight: bold;
  background: url(/assets/sp-background.png) 0 0 / 678px 561px repeat;
}
.headline {
  background-color: #5ABBA2;
  padding: 16px 10px 16px 10px;
  font-size: 3vw;
  text-align: center;
  color: #fff;
  font-weight: 800;
}
.headline.red {
  background-color: #F54336;
}
.headline.yellow {
  background-color: #FBBC43;
  color: #000;
}
.products {
  padding: 10px;
  text-align: left;

  .item {
    background-color: #fff;
    border-radius: 10px;
    margin: 6px;
    display: inline-block;
    overflow: hidden;
    border: 3px solid #fff;
    box-shadow: 1px 4px 10px #3e4040;

    .image {
      text-align: center;
      max-width: 50%;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      width: calc(25vw / 2);
      height: calc(23vw / 2);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item-content {
      width: 100%;
      .item-title {
        width: 100%;
        font-size: 2.5vw;
        margin-top: 10px;
        text-align: center;
        color: #000;
      }
      .interaction button {
        width: 100%;
        margin-top: 8px;
        border: none;
        padding: 11px;
        color: #000;
        font-size: 2.5vw;
        font-weight: bold;
      }
    }
  }
}
.big{  
  font-size: 2.5vw;
  font-weight: 600;
  position: absolute;
  bottom: -10px;
  left: 33%;
  margin: 0 auto;
  width: calc(33% - 9px)!important;
}
.offline {
  background-color: #000;
  position: fixed;
  z-index: 100000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.offline h1 {
  color: #CC0004;
  font-size: 70px;
  text-align: center;
  margin-top: calc(50vh - 42px);
}
.global_button {
  font-family: 'Roboto Slab', serif;
  background-color: #FBBC43;
  position: absolute;
  bottom: 15px;
  text-align: center;
  padding: 15px;
  font-size: 30px;
  border-radius: 10px;
  font-weight: 800;
  z-index: 100;
}
.global_info_button {
  font-family: 'Roboto Slab', serif;
  position: absolute;
  color: #fff;
  bottom: 15px;
  text-align: center;
  padding: 15px;
  font-size: 30px;
  border-radius: 10px;
  font-weight: 800;
}
.global_button.left {
  left: 15px;
  right: auto;
}
.global_button.right {
  right: 15px;
  left: auto;
}
.global_button.disabled {
  opacity: 0.5;
}
.modal-wrapper.ios.sc-ion-modal-ios {
  background: url(/assets/sp-background.png) 0 0 / 678px 561px repeat;
  font-family: "Roboto Slab", serif;
}

/* Touch button clicks! */
.item:active,
.modal_payment .item .icon:active,
.top_button:active,
.keypad-button:active,
.button:active,
.global_button:active {
  opacity: 0.85;
}

/* if aspect ratio 16/9 */
@media screen and (aspect-ratio: 4/3) {
  .global_button, .global_info_button {
    width: calc(33vw - 9px);
    left: calc(50vw - 16.5vw);
  }
}

/* if aspect ratio 16/9 */
@media screen and (aspect-ratio: 16/9) {
  .global_button, .global_info_button {
    width: calc(25vw - 12px);
    left: calc(50vw - 12.5vw);
  }
}
